import { FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes'

const constructBaseQuery = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl, //'http://localhost:7071/api',// // // //'https://api.fusyona.com/nfts/v1',
    prepareHeaders: (headers, { getState }) => {
      // Here set auth headers if needed
      // headers.set('authorization', `Bearer ${token}`)
      return headers
    },
  })

export const baseQuery = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {},
  baseUrl: string
) => {
  const baseQuery = constructBaseQuery(baseUrl)
  let result = await baseQuery(args, api, extraOptions)
  return result
}
