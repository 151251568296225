// constants/index.ts

import { toAbsoluteUrl } from "../_metronic/helpers"


export const toolbarButtonMarginClass = 'ms-1 ms-lg-3'
export const toolbarButtonHeightClass = 'w-90px h-90px btn-icon'
export const toolbarButtonWalletHeightClass = 'btn-secondary h-30px h-md-40px d-flex align-items-center'
export const toolbarUserAvatarHeightClass = 'symbol-30px'
export const toolbarButtonIconSizeClass = 'svg-icon-1'

export const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
  {
    lang: 'es',
    name: 'Español',
    flag: toAbsoluteUrl('/media/flags/spain.svg'),
  },
  {
    lang: 'pt',
    name: 'Português',
    flag: toAbsoluteUrl('/media/flags/brazil.svg'),
  },
]