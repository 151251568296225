import FusySwapFacade from '@fusyona/fusy-swap/offchain/fusy-swap-facade'
import {NetworkInfo} from './networks'

class NetworkClass implements NetworkInfo {
  public url: string
  public logo: string
  public name: string
  public chainId: number
  public explorerUrl: string
  public nativeCurrency: {
    name: string
    symbol: string
    decimals: number
  }

  // Private Facade using singleton
  private facade?: FusySwapFacade

  constructor(data: NetworkInfo) {
    this.url = data.url
    this.logo = data.logo
    this.name = data.name
    this.chainId = data.chainId
    this.explorerUrl = data.explorerUrl
    this.nativeCurrency = data.nativeCurrency
  }
}

export default NetworkClass
