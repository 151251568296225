import {createApi, FetchArgs} from '@reduxjs/toolkit/query/react'
import {BaseQueryApi} from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import {baseQuery} from '../../../services/apiService'
import baseUrls from '../../../services/baseUrls'
import { GameResponseModel } from '../resources/game/game'

export const gameService = createApi({
  reducerPath: 'game',
  tagTypes: ['game'],
  baseQuery: (args: string | FetchArgs, api: BaseQueryApi, extraOptions) => {
    const baseUrl = baseUrls.games + '/games'
    return baseQuery(args, api, extraOptions, baseUrl)
  },

  endpoints: (builder) => ({
    getGame: builder.query<GameResponseModel, string>({
      query: (gameId) => ({
        url: `/${gameId}`,
        method: 'GET',
      }),
      providesTags: ['game'],
    }),
    
  }),
})
export const {
    useLazyGetGameQuery,
} = gameService
