import NetworkClass from './network.class'

export interface NetworkInfo {
  url: string
  logo: string
  name: string
  chainId: number
  explorerUrl: string
  nativeCurrency: {
    name: string
    symbol: string
    decimals: number
  }
  //facade: () => FusySwapFacade | undefined
}
// binance_testnet, ethereum, polygon, binance,
export type Network =
  | 'Sei-Testnet'
  | 'Skale'
  | 'Skale-Testnet'
  | 'Polygon-Mainnet'
  | 'Polygon'
  | 'Taraxa-Mainnet'
  | 'Taraxa'
  | 'BSC-Mainnet'
  | 'Sepolia'
  | 'Ethereum'

export const networkData: Record<Network, NetworkClass> = {
  "Sei-Testnet": new NetworkClass({
    url: 'https://evm-rpc-testnet.sei-apis.com/',
    chainId: 1328,
    explorerUrl: '',
    logo: '',
    name: 'Sei-Testnet',  
    nativeCurrency: {
      name: 'Sei',
      symbol: 'Sei',
      decimals: 18,
    },
  }),
  Skale: new NetworkClass({
    url: 'https://mainnet.skalenodes.com/v1/green-giddy-denebola',
    chainId: 1482601649,
    logo: 'https://nebulachain.io/nebula_gaming_hub_on_skale_files/img/neb_logo.webp',
    explorerUrl: 'https://green-giddy-denebola.explorer.mainnet.skalenodes.com/',
    name: 'Nebula',
    nativeCurrency: {
      name: 'sFUEL',
      symbol: 'SFUEL',
      decimals: 18,
    },
  }),
  'Skale-Testnet': new NetworkClass({
    url: 'https://testnet.skalenodes.com/v1/lanky-ill-funny-testnet',
    chainId: 37084624,
    logo: 'https://nebulachain.io/nebula_gaming_hub_on_skale_files/img/neb_logo.webp',
    explorerUrl: 'https://lanky-ill-funny-testnet.explorer.testnet.skalenodes.com/',
    name: 'Nebula Testnet',
    nativeCurrency: {
      name: 'sFUEL',
      symbol: 'SFUEL',
      decimals: 18,
    },
  }),
  'Polygon-Mainnet': new NetworkClass({
    url: 'https://polygon-mainnet.infura.io/v3/de736e9690cc464cbd7000a8ec2f6fe2',
    logo: '/media/svg/cripto/polygon-circle-purple.svg',
    name: 'Polygon PoS',
    chainId: 137,
    explorerUrl: 'https://polygonscan.com/',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
  }),
  Polygon: new NetworkClass({
    url: 'https://polygon-mumbai.infura.io/v3/de736e9690cc464cbd7000a8ec2f6fe2',
    logo: '/media/svg/cripto/polygon-circle-purple.svg',
    name: 'Mumbai',
    chainId: 80001,
    explorerUrl: 'https://explorer-mumbai.maticvigil.com/',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
  }),
  'Taraxa-Mainnet': new NetworkClass({
    url: 'https://rpc.mainnet.taraxa.io/',
    logo: 'https://testnet.explorer.taraxa.io/favicon.png',
    name: 'Taraxa-Mainnet',
    chainId: 841,
    explorerUrl: 'https://explorer.mainnet.taraxa.io/',
    nativeCurrency: {
      name: 'TARA',
      symbol: 'TARA',
      decimals: 18,
    },
  }),
  Taraxa: new NetworkClass({
    url: 'https://rpc.testnet.taraxa.io/',
    name: 'Taraxa',
    logo: 'https://testnet.explorer.taraxa.io/favicon.png',
    chainId: 842,
    explorerUrl: 'https://explorer.testnet.taraxa.io/',
    nativeCurrency: {
      name: 'TARA',
      symbol: 'TARA',
      decimals: 18,
    },
  }),
  'BSC-Mainnet': new NetworkClass({
    url: 'https://bsc-dataseed.binance.org/',
    name: 'Binance Smart Chain',
    logo: 'https://bscscan.com/images/favicon.png',
    chainId: 56,
    explorerUrl: 'https://bscscan.com/',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
  }),
  Sepolia: new NetworkClass({
    url: 'https://sepolia.infura.io/v3/7d2ecd41c400446585a5d7d8aa93c78d',
    name: 'Sepolia',
    logo: 'https://cryptologos.cc/logos/ethereum-eth-logo.png?v=033',
    chainId: 11155111,
    explorerUrl: 'https://sepolia.etherscan.io/',
    nativeCurrency: {
      name: 'SepoliaETH',
      symbol: 'SepoliaETH',
      decimals: 18,
    },
  }),
  Ethereum: new NetworkClass({
    url: 'https://eth.public-rpc.com',
    name: 'Ethereum',
    logo: 'https://cryptologos.cc/logos/ethereum-eth-logo.png?v=033',
    chainId: 1,
    explorerUrl: 'https://etherscan.io/',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
  }),
}

export function esDireccionWeb3(direccion: string): boolean {
  const regex = /^0x[a-fA-F0-9]{40}$/ // Expresión regular para verificar direcciones Ethereum

  return regex.test(direccion)
}
