import { BaseQueryApi, createApi, FetchArgs } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../../services/apiService";
import { SignedCreateERC20Request, SignedImportERC20Request } from "../resources/tokens/SignedERC20Request";
import { CreateERC20Request } from "../resources/tokens/CreateERC20Request";
import { ImportERC20Request } from "../resources/tokens/ImportERC20Request";


function convertERC20Request(request: CreateERC20Request | ImportERC20Request): FormData {
  const formData = new FormData();
  for (const [key, value] of Object.entries(request)) {
    // Check if the key is one of the image properties
    if (key === 'CoverImage' || key === 'ProfileImage') {
      // Assuming `value` is an object with a `file` property of type `File`
      if (value && 'file' in value && value.file instanceof File) {
        formData.append(key, value.file, value.file.name);
      } else {
        // Handle the case where the file is not present or not a File instance
        console.error(`The file for ${key} is missing or is not a File instance.`);
        // Throw an error or handle this case as appropriate for your application
      }
    } else {
      // For non-file values, convert to string before appending to FormData
      formData.append(key, String(value));
    }
  }
  return formData;
}

export const erc20Service = createApi({
  reducerPath: 'erc20',
  tagTypes: ['erc20'],
  baseQuery: (args: string | FetchArgs, api: BaseQueryApi, extraOptions) => {
    const baseUrl = 'https://erc20-api.azurewebsites.net/api'
    return baseQuery(args, api, extraOptions, baseUrl)
  },
  endpoints: (builder) => ({
    createERC20: builder.mutation<PaymentResponse, SignedCreateERC20Request>({
      query: (erc20) => ({
        url: 'erc20',
        method: 'POST',
        body: convertERC20Request(erc20),
      }),
    }),
    importERC20: builder.mutation<{currency: string}, SignedImportERC20Request>({
      query: (erc20) => ({
        url: 'importWithAttachments',
        method: 'POST',
        body: erc20,
      }),
    }),
    follow: builder.mutation<void, string>({
      query: (pageId: string) => ({
        url: `erc20/${pageId}/follow`,
        method: 'POST',
      }),
    }),
  }),
})


export const {useFollowMutation, useCreateERC20Mutation, useImportERC20Mutation} = erc20Service
