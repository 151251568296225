/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, lazy} from 'react'
import {
  Routes as RoutesDom,
  Route,
  Navigate,
} from 'react-router-dom'
import {MasterLayout} from '../_metronic/layout/MasterLayout'
import {ErrorsPage} from '../components/errors/ErrorsPage'
import ToastManager from '../components/ToastManager/ToastManager'
import { LaunchpadProjectOverview } from '../modules/BondingCurve/components/Launchpad/LaunchpadProjectOverview'
import { LaunchpadProjectSale } from '../modules/BondingCurve/components/Launchpad/LaunchpadProjectSale'
import { LaunchpadProjectAuthor } from '../modules/BondingCurve/components/Launchpad/LaunchpadProjectAuthor'
import { LaunchpadProjectAdmin } from '../modules/BondingCurve/components/Launchpad/LaunchpadProjectAdmin'
import AdvancedSearchWrapper from '../modules/BondingCurve/pages/AdvancedSearchWrapper'
import AdminDetail from '../modules/BondingCurve/pages/AdminDetail'
import { LaunchpadProjectLeaderboard } from '../modules/BondingCurve/components/Launchpad/LaunchpadProjectLeaderboard'
// Lazy loaded routes
const CreateCurveWrapper = lazy(() => import('../modules/BondingCurve/pages/CreateCurveWrapper'))
const BondingCurveExplorerWrapper = lazy(() => import('../modules/BondingCurve/pages/BondingCurveExplorerWrapper'))
const CurveDetailWrapper = lazy(() => import('../modules/BondingCurve/pages/CurveDetailWrapper'))
const CategoryExplorerWrapper  = lazy(() => import( '../modules/BondingCurve/pages/CategoryExplorerWrapper'))


const Routes: FC = () => {
  return (
    <>
      <ToastManager />
      <RoutesDom>
        <Route element={<MasterLayout />}>
          <Route path='/explore' element={<BondingCurveExplorerWrapper />} />
          <Route path='/search' element={<AdvancedSearchWrapper />} />
          <Route path='/explore/:category' element={<CategoryExplorerWrapper />} />
          
          <Route path='/detail/:launchpadId' element={<CurveDetailWrapper />}>
            <Route path='overview' element={<LaunchpadProjectOverview />} />
            <Route path='leaderboard' element={<LaunchpadProjectLeaderboard />} />
            <Route 
                path='sale' 
                element={<LaunchpadProjectSale />} 
              />
            <Route path='author' element={<LaunchpadProjectAuthor />} />
            <Route path='admin' element={<LaunchpadProjectAdmin />} />
          </Route>
          <Route path='/detail/:launchpadId/adminPage/:gameId' element={<AdminDetail/>}/>
          <Route path='/new-bonding-curve' element={<CreateCurveWrapper />} />
          <Route path='/' element={<Navigate to='/explore' />} />
          <Route path='/error' element={<ErrorsPage />} />
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </RoutesDom>
    </>
  )
}

export {Routes}
