import {configureStore} from '@reduxjs/toolkit'
import {setupListeners} from '@reduxjs/toolkit/dist/query';
import createSagaMiddleware from "redux-saga";
import rootSaga from '../../sagas/rootSaga'
import authReducer from '../../slices/authSlice';
import { launchpadService } from '../../modules/BondingCurve/services/launchpadService'
import { universeService } from '../../modules/BondingCurve/services/universeService';
import { gameService } from '../../modules/BondingCurve/services/gameService';
import { socialService } from '../../modules/BondingCurve/services/socialService';
import { erc20Service } from '../../modules/BondingCurve/services/erc20Service';


const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    [launchpadService.reducerPath]: launchpadService.reducer,
    [universeService.reducerPath]: universeService.reducer,
    [gameService.reducerPath]: gameService.reducer,
    [socialService.reducerPath]: socialService.reducer,
    [erc20Service.reducerPath]: erc20Service.reducer,
    auth: authReducer,
  },
  
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(launchpadService?.middleware)
      .concat(universeService?.middleware)
      .concat(gameService?.middleware)
      .concat(socialService?.middleware)
      .concat(erc20Service?.middleware)
      .concat(sagaMiddleware),
})
sagaMiddleware.run(rootSaga);

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
