/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { ProgressBar } from 'react-bootstrap';
import ContractAddress from '../../../../components/ContractAddress';
import { LaunchpadType } from '../../resources/BCLauchpadItem';

interface ILaunchpadSearchItemProps {
  className: string;
  launchpad: LaunchpadType;
  onClick?: () => any
}

const LaunchpadSearchItem: React.FC<ILaunchpadSearchItemProps> = ({ className, launchpad, onClick }) => {
  const intl = useIntl();
  const [isHovered, setIsHovered] = useState(false);

  const link = `/detail/${launchpad.address}`;
  return (
    <div className={`mb-3 mb-sm-0 ${className}`}>
      {/* Player Card */}
      <Link
        to={link}
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={`card `}
        style={{
          transform: isHovered ? 'scale(1.05)' : 'scale(1)',
          transition: 'transform 0.3s',
        }}
      >
        {/* Card Body */}
        <div className='card-body d-flex p-0'>
        {/* User Picture */}
        <div
          className='card-rounded position-relative'
          style={{
            backgroundColor: '#020202',
            backgroundImage: `url(${launchpad?.previewImage?.filePath})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            height: '100px',
            width: '100px',
            borderRadius: '10px',
          }}
        >
          {/* Play Button */}
          <button className='btn btn-icon h-auto w-auto p-0 ms-4 mb-4 position-absolute bottom-0 right-0' data-kt-element='list-play-button'>
            <i className='bi bi-play-fill text-white fs-2x' data-kt-element='list-play-icon'></i>
            <i className='bi bi-pause-fill text-white fs-2x d-none' data-kt-element='list-pause-icon'></i>
          </button>
        </div>

        <div className='d-flex justify-content-between flex-column pt-0 pb-2 px-4 w-100'>
          {/* Title and Author */}
          <div className='d-flex justify-content-between align-items-center mb-2'>
            <h3 className='fw-bold text-gray-800 fs-3'>{launchpad?.title}</h3>
          </div>

          {/* Category */}
          {launchpad?.category && (
            <div className='d-flex flex-stack text-gray-500 fw-bold mb-2'>
              <span>{intl.formatMessage({ id: 'LABEL.CATEGORY' })}: </span>
              <span>{launchpad?.category}</span>
            </div>
          )}
          {/* Author Address */}
          {launchpad?.authorId && (
            <div className='d-flex flex-stack text-gray-500 fw-bold mb-2'>
              <span>{intl.formatMessage({ id: 'LABEL.OWNER' })}: </span>
              <ContractAddress address={launchpad?.authorId} />
            </div>
          )}

          {/* Market Cap and Progress */}
          {launchpad?.progress && +launchpad.progress > 0 &&
            [<div className='d-flex flex-wrap justify-content-between'>

              <span className='fw-bold fs-6 text-white mb-2'>
                {intl.formatMessage({ id: 'LAUNCHPAD_PROGRESS' })} {(+launchpad.progress).toFixed(2)}%
              </span>

            </div>,
            <ProgressBar variant='primary' className='w-100 mb-1' style={{height: '5px'}} now={+launchpad.progress} />
            ]}
        </div>

</div>
      </Link>
    </div>
  );
};

export { LaunchpadSearchItem };
