import React from 'react'
import clsx from 'clsx'
import {useLang, setLanguage} from '../../_metronic/i18n/Metronici18n'
import { languages } from '../../resources/Constants'


const LanguageSelector: React.FC = () => {
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)

  return (
    <div className='d-flex align-items-center'>
      <div
        className='btn btn-active-light-primary position-relative'
        data-kt-menu-trigger='click'
        data-kt-menu-attach='parent'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='bottom'
      >
        <div className='text-gray-600 fw-bold'>
          <span className='symbol symbol-20px me-4'>
            <img className='rounded-1' src={currentLanguage?.flag} alt={currentLanguage?.name} />
          </span>
          {currentLanguage?.name}
        </div>
      </div>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
        data-kt-menu='true'
      >
        {languages.map((language) => (
          <div
            className='menu-item px-3'
            key={language.lang}
            onClick={() => {
              setLanguage(language.lang)
            }}
          >
            <a
              href='#'
              className={clsx('menu-link d-flex px-5', {
                active: language.lang === currentLanguage?.lang,
              })}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={language.flag} alt={language.name} />
              </span>
              {language.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export default LanguageSelector
