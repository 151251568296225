// CustomStaticSelect.tsx
import React, {useState} from 'react'
import Select, {SingleValue, StylesConfig} from 'react-select'
import {useSwitchChain} from 'wagmi'

export interface OptionType {
  label: string
  value: number | string
  image?: string
}

type CustomStaticSelectProps = {
  initialIndex: number
  options: OptionType[]
  onChange: (selectedOption: OptionType | null) => void
  containerMinWidth?: string | number
  imageBorderRadius?: string | number
  [key: string]: any
}

const CustomStaticSelect: React.FC<CustomStaticSelectProps> = ({
  initialIndex,
  options,
  onChange,
  containerMinWidth = '150px',
  imageBorderRadius = '4px',
  ...props
}) => {
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(
    options[initialIndex] || null
  )

  const handleChange = (option: SingleValue<OptionType>) => {
    setSelectedOption(option)
    onChange(option)
  }

  const imag = (url?: string) => url ? ({
    alignItems: 'center',
    display: 'flex',
    ':before': {
      backgroundImage: `url(${url})`,
      backgroundSize: 'contain',
      backgroundColor: 'transparent',
      borderRadius: imageBorderRadius,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 20,
      width: 20,
      backgroundRepeat: 'no-repeat'
    },
  }) : {};

  const colourStyles: StylesConfig<OptionType> = {
    container: (styles) => ({...styles}),
    control: (styles) => ({
      ...styles,
      backgroundColor: 'transparent',
      border: '1px solid rgba(255, 255, 255, 0.25)',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid rgba(255, 255, 255, 0.4)',
      },
    }),
    option: (styles, {data, isDisabled, isFocused, isSelected}) => ({
      ...styles,
      cursor: isDisabled ? 'not-allowed' : 'default',
      background: 'transparent',
      color: 'white',
      ':active': {
        ...styles[':active'],
        background: 'gray',
        color: 'white',
      },
      ...imag(data?.image),
    }),
    input: (styles) => ({...styles, textTransform: 'uppercase'}),
    placeholder: (styles) => ({...styles}),
    menu: (styles) => ({...styles, background: 'var(--kt-toolbar-bg-color)'}),
    singleValue: (styles, {data}) => ({...styles, color: 'white', ...imag(data?.image)}),
  }

  return (
    <Select
      options={options}
      value={selectedOption}
      styles={colourStyles}
      onChange={handleChange}
      {...props}
      isMulti={false}
    />
  )
}

export default CustomStaticSelect
