import React from 'react'
import clsx from 'clsx'
import NetworkSelector from '../../../../components/NetworkSelector'
import LanguageSelector from '../../../../components/LanguageSelector'
import { useAppSelector } from '../../../../hooks/hooks'
import SearchHandler from '../../../../components/SearchHandler'
import { useLazySearchQuery } from '../../../../modules/BondingCurve/services/launchpadService'
import { ListResponse } from '../../../../resources/ListResponse'
import { LaunchpadResponseModel } from '../../../../modules/BondingCurve/resources/launchpad/LaunchpadResponseModel'
import { LaunchpadSearchItem } from '../../../../modules/BondingCurve/components/Launchpad/LaunchpadSearchItem'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3';

const Topbar: React.FC = () => {
  const token = useAppSelector((state) => state.auth.token)
  const isAuthorized = Boolean(token)

  const renderSearchResult = (data: ListResponse<any>, closeModal: () => any) => {
    console.log(data);
    return <div>
      {
        data.data.map((d: LaunchpadResponseModel) => <LaunchpadSearchItem launchpad={d} className='pb-2' onClick={closeModal}/>)}
    </div>
  }

  const [fetchSearchData, { data }] = useLazySearchQuery();
  
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <SearchHandler fetchData={(params) => fetchSearchData({...params}).unwrap()}  renderResult={renderSearchResult} />
      </div>
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <w3m-button />
      </div>
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <NetworkSelector />
      </div>
      {!isAuthorized && (
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <LanguageSelector />
        </div>
      )}
    </div>
  )
}

export default Topbar
