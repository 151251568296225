import { FC } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import { Leaderboard } from "../components/Details/Leaderboard";
import { useNavigate, useParams } from "react-router";

type QueryParams = {
    gameId: string
    launchpadId: string
}

const AdminDetail: FC = () => {
    const { gameId, launchpadId } = useParams<QueryParams>()
    const intl = useIntl()

    if (!gameId) {
        return <></>
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'PAGE.CREATE_NEW_LAUNCHPAD' })}</PageTitle>
            <Leaderboard className={""} gameId={gameId} />
        </>
    )
}

export default AdminDetail;
