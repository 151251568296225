import React from 'react'
import {useIntl} from 'react-intl'
import { Link } from 'react-router-dom'
import { getCurrentUrl, toAbsoluteUrl } from '../../../../helpers'
import { MenuInnerWithSub } from '../MenuInnerWithSub'
import { MenuItem } from '../MenuItem'
export function MenuInnerWithLogo() {
  const intl = useIntl()
  return (
    <>
    <div className='aside-logo flex-column-auto align-items-center me-10' id='kt_aside_logo'>

      <Link to='/' >
        <img
          alt='Logo'
          className='h-60px logo mt-2'
          src={toAbsoluteUrl('/media/logos/logo.png')}
        />
      </Link>
      </div>
      <div className='d-flex align-items-center mx-auto w-100 justify-content-center gap-1'>

      <MenuItem
          title={intl.formatMessage({id: 'MENU.EXPLORE'})}
          fontIcon='bi bi-search'
          to='/explore'
          extraCondition={(pathname, to) => getCurrentUrl(pathname).indexOf('/Playable') == -1 }
          hasBullet={false}
          />
          <MenuItem
          title={intl.formatMessage({id: 'MENU.PLAYABLE'})}
          fontIcon='bi bi-controller'
          to='/explore/Playable'
          hasBullet={false}
          />
          </div>
      {/* 
      <MenuInnerWithSub
        title={intl.formatMessage({id: 'MENU.WALLET'})}
        to='/wallet'
        icon='/media/icons/duotune/finance/fin008.svg'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem
          icon='/media/icons/duotune/general/gen008.svg'
          to='/wallet'
          title={intl.formatMessage({id: 'MENU.OVERVIEW'})}
          hasBullet={false}
          
        />
        <MenuItem
          fontIcon='bi bi-box-arrow-in-down'
          to='/deposits'
          title={intl.formatMessage({id: 'MENU.DEPOSITS'})}
          hasBullet={false}
        />
        <MenuItem
          fontIcon='bi bi-box-arrow-up'
          to='/withdrawals'
          title={intl.formatMessage({id: 'MENU.WITHDRAWALS'})}
          hasBullet={false}
        />
      </MenuInnerWithSub> */}
    </>
  )
}
