import React from 'react';
import ReactMarkdown from 'react-markdown';

interface Step {
  title: string;
  description: string;
}

const steps: Step[] = [
  {
    title: 'Step 1: Create a New Bot on Telegram',
    description: `Open Telegram and search for the \`@BotFather\` bot, which is the official Telegram bot used to create and manage bots.
Start a chat with \`@BotFather\` and type \`/newbot\` to initiate the bot creation process.
Follow the prompts to:
- **Name your bot** (this will be the display name).
- **Choose a username** for the bot, which must end in "bot" (e.g., ExampleBot or Example_bot).
@BotFather will generate a Bot Token after creating your bot. Copy this token—this is your **Secret Key**. The token looks like a long string of letters and numbers.`,
  },
  {
    title: 'Step 2: Fill in the Form Fields',
    description: `- **Telegram Bot Name**: Enter the username of your bot (e.g., @ExampleBot).
- **Secret Key**: Paste the Bot Token (your secret key) generated by @BotFather.`,
  },
  {
    title: 'Step 3: Submit the Form',
    description: 'After entering the **Bot Name** and **Secret Key** into the form fields, click Submit.',
  },
  {
    title: 'Step 4: Configure missions',
    description: 'Now you can add the missions on the **missions settings**.',
  },
];

const TelegramBotHelp: React.FC = () => {
  return (
    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
      {/* begin::Wrapper */}
      <div className="d-flex flex-column flex-grow-1">
        {/* begin::Content */}
        <h4 className="text-gray-900 fw-bold mb-4">Telegram Bot Setup Guide</h4>
        <div className="fs-6 text-gray-700">
          {steps.map((step, index) => (
            <div key={index} className="mb-4">
              <h5 className="fw-semibold">{step.title}</h5>
              <ReactMarkdown>{step.description}</ReactMarkdown>
            </div>
          ))}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Wrapper */}
    </div>
  );
};

export default TelegramBotHelp;
