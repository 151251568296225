import React, { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useWeb3Context } from '../../../../contexts/Web3';
import { LaunchpadType } from '../../resources/BCLauchpadItem';
import TelegramBotHelp from '../TelegramBotHelp';
import { KTSVG } from '../../../../_metronic/helpers';
import { Link } from 'react-router-dom';


type OutletContext = {
  launchpad: LaunchpadType;
  className: string;
  ownerAddress: string;
};

const LaunchpadProjectAdmin: React.FC = () => {
  const { launchpad, className, ownerAddress } = useOutletContext<OutletContext>();
  const { isConnected, accountAddress } = useWeb3Context();
  const authorized = useMemo(() => !isConnected || accountAddress != ownerAddress, [isConnected, accountAddress])

  const formik = useFormik({
    initialValues: {
      botname: '',
      secretKey: '',
    },
    validationSchema: Yup.object({
      botname: Yup.string()
        .required('Telegram bot name is required')
        .test('starts-with-at', 'Telegram bot name should start with "@"', (value) => {
          return value ? value.startsWith('@') : true;
        }),
      secretKey: Yup.string().required('Secret key is required'),
    }),
    onSubmit: (values) => {
      if (!values.botname.startsWith('@')) {
        values.botname = `@${values.botname}`;
      }
      console.log('Form Values:', values);
      // Submit the form values here or handle them as needed
    },
  });


  return authorized ? (
    <div className={`${className}`}>
      <div className='card' style={{ margin: '0', padding: '20px' }}>
        <div className='justify-content-end d-flex'>
        <Link to={`../adminPage/${launchpad.gameId}`} className='btn btn-secondary fw-bold fs-7 py-2 px-3 align-items-center d-flex' type='submit'>
          <KTSVG path='/media/icons/duotone/Code/Settings4.svg' className='svg-icon-2' /> Missions
        </Link>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className='mb-5'>
            <label htmlFor='botname' className='required form-label mb-3'>Telegram Bot Name</label>
            <input
              type='text'
              id='botname'
              name='botname'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.botname}
              placeholder='@BotName'
              className={'form-control form-control-lg form-control-solid' + (formik.touched.botname && formik.errors.botname ? 'input-error' : '')}
            />
            {formik.touched.botname && formik.errors.botname ? (
              <div className='error-message'>{formik.errors.botname}</div>
            ) : null}
          </div>

          <div className='mb-5'>
            <label htmlFor='secretKey' className='required form-label mb-3'>Secret Key</label>
            <input
              type='password'
              id='secretKey'
              name='secretKey'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.secretKey}
              className={'form-control form-control-lg form-control-solid' + (formik.touched.secretKey && formik.errors.secretKey ? 'input-error' : '')}
            />
            {formik.touched.secretKey && formik.errors.secretKey ? (
              <div className='error-message'>{formik.errors.secretKey}</div>
            ) : null}
          </div>

          <button className='btn bg-hover-success text-hover-white text-success bg-light-success fw-bold' type='submit'>Submit</button>
        </form>
        <div className='mt-4'>
          <TelegramBotHelp />
        </div>
      </div>
    </div>
  ) : <></>;
};

export { LaunchpadProjectAdmin };
