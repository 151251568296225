import React from 'react'
import ReactDOM from 'react-dom/client'
// Redux
// https://github.com/rt2zz/redux-persist
import {Provider} from 'react-redux'
import * as _redux from './setup'
import {store} from './setup'

import {Chart, registerables} from 'chart.js'

// Apps
import {App} from './App'
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/

import 'bootstrap-daterangepicker/daterangepicker.css'

import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'

import './_metronic/assets/sass/ag-theme-fusyona-dark.css'
import './_metronic/assets/sass/breadcrumb.css'
import './_metronic/assets/sass/breadcrumb.scss'

import '@yaireo/tagify/dist/tagify.css'

import '@szhsin/react-menu/dist/core.css'

import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import { Web3ModalAppProvider } from './contexts/Web3/Web3ModalAppProvider'
import { Web3ContextProvider } from './contexts/Web3'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {REACT_APP_SITE_URL} = process.env

Chart.register(...registerables)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <MetronicI18nProvider>
    <Provider store={store}>
      <Web3ModalAppProvider>
        <Web3ContextProvider>
          <App basename={''} />
        </Web3ContextProvider>
      </Web3ModalAppProvider>
    </Provider>
  </MetronicI18nProvider>
)
