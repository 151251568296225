import React, {createContext, FC, useContext, useState} from 'react'
import {Connector} from 'wagmi'
import FusySwapFacade from '@fusyona/fusy-swap/offchain/fusy-swap-facade'
import NetworkClass from '../../utils/network.class'
import { networkData } from '../../utils/networks'
import { chainInfo, UniverseChainInfo } from '../../resources/chains'

export interface Web3ContextValues {
  provider: any
  isConnected: boolean
  isLoading: boolean
  accountAddress: string
  setProvider: any
  setIsConnected: any
  setIsLoading: any
  signMessage: (message: string) => Promise<string>
  network: UniverseChainInfo
  connector: Connector | undefined
  setNetwork: any
  facade: FusySwapFacade | undefined
}

const Web3Context = createContext<Web3ContextValues>({
  provider: null,
  isConnected: false,
  isLoading: false,
  accountAddress: '',
  setProvider: null,
  setIsConnected: null,
  setIsLoading: null,
  signMessage: () => Promise.resolve(""),
  network: chainInfo[842],
  connector: {} as Connector,
  setNetwork: null,
  facade: {} as FusySwapFacade,
})
export default Web3Context

export const useWeb3Context = () => {
  return useContext(Web3Context)
}
