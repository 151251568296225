import React, {useEffect, useState} from 'react'
import * as marked from 'marked'
import {useOutletContext} from 'react-router-dom'
import DOMPurify from 'dompurify'
import { useGetSingleLaunchpadByAddressOrIdQuery } from '../../services/launchpadService';
import { LaunchpadType } from '../../resources/BCLauchpadItem';

type OutletContext = {
  launchpad: LaunchpadType
  className: string
}

marked.setOptions({
  gfm: true,
  breaks: true,
})

const LaunchpadProjectAuthor: React.FC = () => {
  const {launchpad, className} = useOutletContext<OutletContext>()
  const [markdownHTML, setMarkdownHTML] = useState<string>('')
  const {data: launchpad_from_web2, isSuccess} = useGetSingleLaunchpadByAddressOrIdQuery(
    launchpad.id || ''
  )

  useEffect(() => {
    const fetchMarkdown = async () => {
      if (launchpad_from_web2?.authorDescription) {
        const rawHTML: string = await marked.parse(launchpad_from_web2?.authorDescription || '')

        const styledHTML = rawHTML.replace(
          /<iframe /g,
          '<iframe style="width: 100%; height: 500px;" '
        )

        // Sanitize the HTML, allowing only safe YouTube embeds
        const sanitizedHTML = DOMPurify.sanitize(styledHTML, {
          ALLOWED_TAGS: [
            'iframe',
            'p',
            'b',
            'i',
            'strong',
            'em',
            'a',
            'ul',
            'ol',
            'li',
            'blockquote',
            'h1',
            'h2',
            'h3',
            'h4',
            'h5',
            'h6',
          ],
          ALLOWED_ATTR: [
            'src',
            'width',
            'height',
            'frameborder',
            'allow',
            'allowfullscreen',
            'style',
          ],
          ALLOWED_URI_REGEXP: /^https:\/\/www\.youtube\.com\/embed\//, // Only allow YouTube embeds
        })

        setMarkdownHTML(sanitizedHTML)
      }
    }

    fetchMarkdown()
  }, [launchpad])

  const markdownStyles = {
    marginBottom: '20px',
    lineHeight: '1.6',
    fontSize: '14px',
    color: '#d1d5db',
  }

  const headingStyles = {
    marginTop: '20px',
    marginBottom: '10px',
    fontWeight: 'bold',
    color: '#ffffff',
  }

  const listStyles = {
    paddingLeft: '20px',
    marginBottom: '15px',
  }

  const linkStyles = {
    color: '#3b82f6',
    textDecoration: 'underline',
  }

  const linkHoverStyles = {
    color: '#2563eb',
    textDecoration: 'none',
  }

  return (
    <div className={`${className}`}>
      <div className='card' style={{margin: '0', padding: '20px'}}>
        <div className='d-flex align-items-center border-1 border-dashed card-rounded p-5 p-lg-10 mb-14'>
          {/* Section */}
          <div className='text-center flex-shrink-0 me-7 me-lg-13'>
            {/* Avatar */}
            <div className='symbol symbol-70px symbol-circle mb-2'>
              <img src='/media/svg/avatars/001-boy.svg' alt={launchpad_from_web2?.author} />
            </div>
            {/* Info */}
            <div className='mb-0'>
              <a href='#' className='text-gray-700 fw-bold text-hover-primary'>
                {launchpad_from_web2?.author}
              </a>
              <span className='text-gray-500 fs-7 fw-semibold d-block mt-1'>Author</span>
            </div>
          </div>

          {/* Text */}
          <div className='mb-0 fs-6'>
            <div
              className='markdown-content'
              style={{...markdownStyles, margin: '0', padding: '10px'}}
              dangerouslySetInnerHTML={{__html: markdownHTML}}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export {LaunchpadProjectAuthor}
