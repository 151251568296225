import {createApi, FetchArgs} from '@reduxjs/toolkit/query/react'
import {Page} from '../resources/social/Page'

import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { Token } from '../resources/social/Token'
import { ListResponse } from '../../../resources/ListResponse'
import { baseQuery } from '../../../services/apiService'

export interface PageRequest {
  contract: string;
  network: string;
}

export const socialService = createApi({
  reducerPath: 'social',
  tagTypes: ['social'],
  baseQuery: (args: string | FetchArgs, api: BaseQueryApi, extraOptions) => {
    const baseUrl = 'https://brokerstsocialapi.azurewebsites.net';
     return baseQuery(args,api,extraOptions, baseUrl);
  },
 
  endpoints: (builder) => ({
    getTokensPage: builder.query<ListResponse<Token>, number>({
      query: (page = 1) => ({
        url: `tokens/page/${page}`,
        method: 'GET',  
      }),
    }),
    getPages: builder.query<Page[], void>({
      query: () => ({
        url: 'pages',
        method: 'GET',
      }),
    }),
    getPagesWithPagination: builder.query<ListResponse<Page>, {pageNumber:number, network:string}>({
      query: ({pageNumber, network}) => ({
        url: `pages/page/${pageNumber}?listingIdentifiers=Fusy&network=${network}`,
        method: 'GET',
      }),
    }),
    getSinglePage: builder.query<Page, string>({
      query: (tokenId) => ({
        url: `pages/${tokenId}`,
        method: 'GET',
      }),
    }),
    getSinglePageByContractAndNetwork: builder.query<Page, PageRequest>({
      query: (req) => ({
        url: `pages/contract/${req.contract}/network/${req.network}`,
        method: 'GET',
      }),
    }),
    getSinglePageTokens: builder.query<Token[], string>({
      query: (tokenId) => ({
        url: `pages/${tokenId}/tokens`,
        method: 'GET',
      }),
    }),
  }),
})


export const {
  useGetSinglePageTokensQuery,
  useGetTokensPageQuery,
  useGetPagesQuery,
  useGetPagesWithPaginationQuery,
  useGetSinglePageQuery,
  useGetSinglePageByContractAndNetworkQuery,
  useLazyGetSinglePageByContractAndNetworkQuery
} = socialService
