import React, { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useWeb3Context } from '../../../../contexts/Web3';
import { LaunchpadType } from '../../resources/BCLauchpadItem';
import TelegramBotHelp from '../TelegramBotHelp';
import { Leaderboard } from '../Details/Leaderboard';

type OutletContext = {
  launchpad: LaunchpadType;
  className: string;
  ownerAddress: string;
};

const LaunchpadProjectLeaderboard: React.FC = () => {
  const { launchpad, className, ownerAddress } = useOutletContext<OutletContext>();
  const { isConnected, accountAddress } = useWeb3Context();

  return <div className={`${className}`}>
    {launchpad.gameId ? <Leaderboard gameId={launchpad.gameId}/> : <span>Error loading launchpad</span>}
  </div>
};

export { LaunchpadProjectLeaderboard };
