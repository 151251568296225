/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { ProgressBar } from 'react-bootstrap';
import { useGetSingleLaunchpadByAddressOrIdQuery } from '../services/launchpadService';
import  { LaunchpadType } from '../resources/BCLauchpadItem';
import ContractAddress from '../../../components/ContractAddress';

interface IBondingCurveListItemProps {
  className: string;
  launchpad: LaunchpadType;
  playable?: boolean;
}

const BondingCurveListItem: React.FC<IBondingCurveListItemProps> = ({ className, launchpad, playable }) => {
  const intl = useIntl();
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const link = `/detail/${launchpad.address}/overview`;
  const handlePlay = () => {
    navigate(`${link}?play=true`)
  }

  return (
    <div className={`mb-3 mb-sm-0 ${className}`}>
      {/* Player Card */}
      <Link
        to={link}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={`card`}
        style={{
          transform: isHovered ? 'scale(1.05)' : 'scale(1)',
          transition: 'transform 0.3s',
        }}
      >
        {/* User Picture */}
        <div
          className='card-rounded position-relative mb-5'
          style={{
            backgroundColor: '#020202',
            backgroundImage: `url(${launchpad?.previewImage?.filePath})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            height: '200px',
            borderRadius: '10px 10px 0 0',
          }}
        >
          {/* Play Button */}
          <button className='btn btn-icon h-auto w-auto p-0 ms-4 mb-4 position-absolute bottom-0 right-0' data-kt-element='list-play-button'>
            <i className='bi bi-play-fill text-white fs-2x' data-kt-element='list-play-icon'></i>
            <i className='bi bi-pause-fill text-white fs-2x d-none' data-kt-element='list-pause-icon'></i>
          </button>
        </div>

        {/* Card Body */}
        <div className='card-body d-flex justify-content-between flex-column pt-0 pb-2 px-4'>
          {/* Title and Author */}
          <div className='d-flex justify-content-between align-items-center mb-2'>
            <h3 className='fw-bold text-gray-800 fs-3'>{launchpad?.title}</h3>
          </div>

          {/* Author Address */}
          {launchpad?.authorId && (
            <div className='d-flex flex-stack text-gray-500 fw-bold mb-2'>
              <span>{intl.formatMessage({ id: 'LABEL.OWNER' })}: </span>
              <ContractAddress address={launchpad?.authorId} />
            </div>
          )}

          {/* Market Cap and Progress */}
          {launchpad?.progress && +launchpad.progress > 0 &&
            [<div className='d-flex flex-wrap justify-content-between'>

              <span className='fw-bold fs-6 text-white mb-2'>
                {intl.formatMessage({ id: 'LAUNCHPAD_PROGRESS' })} {(+launchpad.progress).toFixed(2)}%
              </span>

            </div>,
            <ProgressBar variant='primary' className='w-100 mb-1' style={{height: '5px'}} now={+launchpad.progress} />
            ]}

            {playable && 
           <button className='btn btn-secondary w-100 mt-2 py-2 fw-bold' onClick={handlePlay}>
           <i className='bi bi-play-fill text-white fs-2x' data-kt-element='list-play-icon'></i>
           <span className="me-5">Play</span></button> }
        </div>
      </Link>
    </div>
  );
};

export { BondingCurveListItem };
