import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { LaunchpadInformation } from './LaunchpadInformation';
import { LaunchpadType } from '../../resources/BCLauchpadItem';

type OutletContext = {
  launchpad: LaunchpadType;
  className: string;
};

const LaunchpadProjectSale: React.FC = () => {
  const { launchpad, className } = useOutletContext<OutletContext>();

  return (
    <div className={className}>
      <LaunchpadInformation launchpad={launchpad} className={''} />
    </div>
  );
};

export { LaunchpadProjectSale };
